<script>
import appConfig from '../../../../src/app.config'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import Preloader from "@/components/widgets/preloader";
import {email, minLength, required} from 'vuelidate/lib/validators'
import Vue from "vue";
import { SUBACCOUNT_TYPES, SUBACCOUNT_TYPE_LABELS, FOSTER_FAMILY_TYPE_LABELS } from '@/constants/subaccount-types'

import Repository from "../../../app/repository/repository-factory";

const SubaccountRepository = Repository.get("SubaccountRepository");

  const isNotSameAsGuardian1Email = (value, vm) => (value === null || value.length === 0 || value !== vm.guardian1Email);
  const isNotSameAsGuardian2Email = (value, vm) => (value === null || value.length === 0 || value !== vm.guardian2Email);
  const isPesel = (value) => {
    if (!value || value.trim() === '') return true;
    return value.length === 11 && /^\d+$/.test(value);
  };

/**
 * Form validation component
 */
export default {
  page: {
    title: '',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, Preloader, DatePicker },
  data() {
    return {
      SUBACCOUNT_TYPES,
      types: Object.entries(SUBACCOUNT_TYPE_LABELS).map(([value, text]) => ({ value, text })),
      fosterFamilyTypes: Object.entries(FOSTER_FAMILY_TYPE_LABELS).map(([value, text]) => ({ value, text })),
      inProgress: false,
      preloader: true,
      title: 'Dodaj podopiecznego',
      items: [
        {
          text: 'Podopieczni',
          to: { name: 'Lista podopiecznych' }
        },
        {
          text: 'Dodaj',
          active: true,
        },
      ],
      form: {
        type: SUBACCOUNT_TYPES.BENEFICIARY,
        fosterFamilyType: null,
        firstName: '',
        lastName: '',
        addressStreet: '',
        addressCity: '',
        addressPostcode: '',
        pesel: '',
        dateOfBirth: '',
        email: '',
        medicalDiagnosis: '',
        guardian1Name: '',
        guardian1Phone: '',
        guardian1Email: '',
        guardian1Pesel: '',
        guardian1AccessToApp: false,
        guardian2Name: '',
        guardian2Phone: '',
        guardian2Email: '',
        guardian2Pesel: '',
        guardian2AccessToApp: false,
        bankAccount: '',
        profileImageUrl: '',
        isActive: true,
        notes: ''
      },
      submitted: false
    }
  },
  validations: {
    form: {
      type: { required },
      fosterFamilyType: { 
        required(value) {
          if (this.form.type !== SUBACCOUNT_TYPES.FOSTER_FAMILY) {
            return true;
          }
          return !!value;
        }
      },
      firstName: { required, minLength: minLength(2)  },
      lastName: { required, minLength: minLength(2) },
      addressStreet: { required },
      addressCity: { required },
      addressPostcode: { required },
      pesel: { required },
      dateOfBirth: { required },
      email: { required, email },
      medicalDiagnosis: { },
      guardian1Name: { },
      guardian1Phone: { },
      guardian1Email: { email, isNotSameAsGuardian2Email },
      guardian1Pesel: { isPesel },
      guardian2Name: { },
      guardian2Phone: { },
      guardian2Email: { email, isNotSameAsGuardian1Email },
      guardian2Pesel: { isPesel },
      bankAccount: { required },
      profileImageUrl: { },
      isActive: { required },
      notes: { }
    }
  },
  watch: {
    'form.type': function(newVal) {
      if (newVal === SUBACCOUNT_TYPES.BENEFICIARY) {
        this.form.fosterFamilyType = null;
      }
    }
  },
  mounted() {
    this.preloader = false;
  },
  methods: {
    add() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      this.inProgress = true;
      const formData = { ...this.form };
      formData.guardian1Pesel = formData.guardian1Pesel?.trim() || null;
      formData.guardian2Pesel = formData.guardian2Pesel?.trim() || null;

      SubaccountRepository.add(formData).then((response) => {
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Podopieczny został dodany!',
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
            this.$router.push({ name: "Edycja podopiecznego", params: { id: response.data.uuid }})
          }
        });
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.inProgress = false;
      })
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
    }
  },
}
</script>

<template>
  <Layout>
    <PageHeader 
      :title="form.type === SUBACCOUNT_TYPES.FOSTER_FAMILY ? 
        `Dodawanie rodziny zastępczej - ${form.firstName} ${form.lastName}` : 
        `Dodawanie podopiecznego - ${form.firstName} ${form.lastName}`" 
      :items="items" />
    <preloader v-if="preloader"></preloader>

    <div class="row">
      <div class="col-lg-12 col-xl-7">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dane podstawowe</h4>
            <p class="card-title-desc">{{ form.type === SUBACCOUNT_TYPES.FOSTER_FAMILY ? 'Uzupełnij dane szczegółowe opiekuna.' : 'Uzupełnij dane podopiecznego.' }}</p>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group required">
                    <label for="type">Typ:</label>
                    <select 
                      id="type" 
                      v-model="form.type" 
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.type.$error }">
                      <option 
                        v-for="type in types" 
                        :key="type.value" 
                        :value="type.value">
                        {{ type.text }}
                      </option>
                    </select>
                    <div v-if="submitted && $v.form.type.$error" class="invalid-feedback">
                      <span v-if="!$v.form.type.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" v-if="form.type === SUBACCOUNT_TYPES.FOSTER_FAMILY">
                  <div class="form-group required">
                    <label for="fosterFamilyType">Typ rodziny zastępczej:</label>
                    <select 
                      id="fosterFamilyType" 
                      v-model="form.fosterFamilyType" 
                      class="form-control"
                      :class="{ 'is-invalid': submitted && $v.form.fosterFamilyType.$error }">
                      <option 
                        v-for="type in fosterFamilyTypes" 
                        :key="type.value" 
                        :value="type.value">
                        {{ type.text }}
                      </option>
                    </select>
                    <div v-if="submitted && $v.form.fosterFamilyType.$error" class="invalid-feedback">
                      <span v-if="!$v.form.fosterFamilyType.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group required">
                    <label for="firstName">{{ form.type === SUBACCOUNT_TYPES.FOSTER_FAMILY ? 'Imię głównego opiekuna' : 'Imię' }}:</label>
                    <input
                      id="firstName"
                      v-model="form.firstName"
                      type="text"
                      class="form-control"
                      :placeholder="form.type === SUBACCOUNT_TYPES.FOSTER_FAMILY ? 'Imię głównego opiekuna' : 'Imię'"
                      :class="{ 'is-invalid': submitted && $v.form.firstName.$error }"
                    />
                    <div v-if="submitted && $v.form.firstName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.firstName.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.firstName.minLength">Ta wartość musi być dłuższa niż 1 znak.<br></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group required">
                    <label for="lastName">{{ form.type === SUBACCOUNT_TYPES.FOSTER_FAMILY ? 'Nazwisko głównego opiekuna' : 'Nazwisko' }}:</label>
                    <input
                      id="lastName"
                      v-model="form.lastName"
                      type="text"
                      class="form-control"
                      :placeholder="form.type === SUBACCOUNT_TYPES.FOSTER_FAMILY ? 'Nazwisko głównego opiekuna' : 'Nazwisko'"
                      :class="{ 'is-invalid': submitted && $v.form.lastName.$error }"
                    />
                    <div v-if="submitted && $v.form.lastName.$error" class="invalid-feedback">
                      <span v-if="!$v.form.lastName.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.lastName.minLength">Ta wartość musi być dłuższa niż 1 znak.<br></span>
                    </div>
                  </div>
                </div>
              </div>

               <div class="row">
                <div class="col-md-6">
                  <div class="form-group required">
                    <label for="email">E-mail:</label>
                    <input
                      id="email"
                      v-model="form.email"
                      type="text"
                      class="form-control"
                      placeholder="E-mail"
                      :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                    />
                    <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                      <span v-if="!$v.form.email.required">Ta wartość jest wymagana.<br></span>
                      <span v-if="!$v.form.email.email">Wpisz poprawny adres e-mail.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group required">
                    <label for="pesel">PESEL:</label>
                    <input
                      id="pesel"
                      v-model="form.pesel"
                      v-mask="'###########'"
                      type="text"
                      class="form-control"
                      placeholder="PESEL"
                      :class="{ 'is-invalid': submitted && $v.form.pesel.$error }"
                    />
                    <div v-if="submitted && $v.form.pesel.$error" class="invalid-feedback">
                      <span v-if="!$v.form.pesel.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group required">
                    <label for="dateOfBirth">Data urodzenia:</label>
                    <date-picker
                            id="dateOfBirth"
                            v-model="form.dateOfBirth"
                            :first-day-of-week="1"
                            :class="{ 'is-invalid': submitted && $v.form.dateOfBirth.$error }"
                            value-type="format"
                            lang="pl">
                    </date-picker>
                    <div v-if="submitted && $v.form.dateOfBirth.$error" class="invalid-feedback">
                      <span v-if="!$v.form.dateOfBirth.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group required">
                    <label for="addressStreet">Ulica:</label>
                    <input
                      id="addressStreet"
                      v-model="form.addressStreet"
                      type="text"
                      class="form-control"
                      placeholder="Ulica"
                      :class="{ 'is-invalid': submitted && $v.form.addressStreet.$error }"
                    />
                    <div v-if="submitted && $v.form.addressStreet.$error" class="invalid-feedback">
                      <span v-if="!$v.form.addressStreet.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group required">
                    <label for="addressCity">Miasto:</label>
                    <input
                      id="addressCity"
                      v-model="form.addressCity"
                      type="text"
                      class="form-control"
                      placeholder="Miasto"
                      :class="{ 'is-invalid': submitted && $v.form.addressCity.$error }"
                    />
                    <div v-if="submitted && $v.form.addressCity.$error" class="invalid-feedback">
                      <span v-if="!$v.form.addressCity.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group required">
                    <label for="addressPostcode">Kod:</label>
                    <input
                      id="addressPostcode"
                      v-model="form.addressPostcode"
                      v-mask="'##-###'"
                      type="text"
                      class="form-control"
                      placeholder="Kod pocztowy"
                      :class="{ 'is-invalid': submitted && $v.form.addressPostcode.$error }"
                    />
                    <div v-if="submitted && $v.form.addressPostcode.$error" class="invalid-feedback">
                      <span v-if="!$v.form.addressPostcode.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group required">
                    <label for="bankAccount">Numer konta:</label>
                    <input
                      id="bankAccount"
                      v-model="form.bankAccount"
                      type="text"
                      class="form-control"
                      placeholder="Numer konta"
                      v-mask="'XX XXXX XXXX XXXX XXXX XXXX XXXX'"
                      value=""
                      :class="{ 'is-invalid': submitted && $v.form.bankAccount.$error }"
                    />
                    <div v-if="submitted && $v.form.bankAccount.$error" class="invalid-feedback">
                      <span v-if="!$v.form.bankAccount.required">Ta wartość jest wymagana.<br></span>
                    </div>
                  </div>
                </div>
              </div>

               <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="medicalDiagnosis">Diagnoza medyczna:</label>
                    <input
                      id="medicalDiagnosis"
                      v-model="form.medicalDiagnosis"
                      type="text"
                      class="form-control"
                      placeholder="Wpisz diagnozę medyczną..."
                      value=""
                      :class="{ 'is-invalid': submitted && $v.form.medicalDiagnosis.$error }"
                    />
                    <div v-if="submitted && $v.form.medicalDiagnosis.$error" class="invalid-feedback">
                      <span v-if="!$v.form.medicalDiagnosis.required">Ta wartość jest wymagana.<br></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="medicalDiagnosis">URL zdjęcia profilowego:</label>
                    <input
                            id="profileImageUrl"
                            v-model="form.profileImageUrl"
                            type="text"
                            class="form-control"
                            placeholder="Wklej adres zdjęcia..."
                            value=""
                            :class="{ 'is-invalid': submitted && $v.form.profileImageUrl.$error }"
                    />
                  </div>
                </div>
              </div>

               <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="notes">Uwagi:</label>
                    <b-form-textarea
                    v-model="form.notes"
                    id="notes"
                    rows="5">
                    </b-form-textarea>
                  </div>
                </div>
              </div>

               <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input
                      id="isActive"
                      v-model="form.isActive"
                      type="checkbox"
                      class="custom-control-input" />
                      <label
                        class="custom-control-label"
                        for="isActive">Konto aktywne?</label>
                    </div>
                  </div>
                </div>
              </div>

              <button class="btn btn-primary" v-on:click="add" :disabled="this.$v.$error" type="submit">
                {{ form.type === SUBACCOUNT_TYPES.FOSTER_FAMILY ? 'Dodaj rodzinę zastępczą' : 'Dodaj podopiecznego' }}
              </button>
              <template v-if="inProgress" >
                <b-spinner v-for="n in 3" :key="n" small class="m-0" variant="light" role="status" type="grow"></b-spinner>
              </template>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-lg-12 col-xl-5">
                <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dane opiekuna 1</h4>
            <p
              class="card-title-desc"
            >Uzupełnij dane opiekuna.</p>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="guardian1Name">Imię i nazwisko:</label>
                    <input
                      id="guardian1Name"
                      v-model="form.guardian1Name"
                      type="text"
                      class="form-control"
                      placeholder="Imię i nazwisko"
                      value=""
                      :class="{ 'is-invalid': submitted && $v.form.guardian1Name.$error }"
                    />
                    <div v-if="submitted && $v.form.guardian1Name.$error" class="invalid-feedback">
                      <span v-if="!$v.form.guardian1Name.required">Ta wartość jest wymagana.<br></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="guardian1Email">E-mail:</label>
                    <input
                      id="guardian1Email"
                      v-model="form.guardian1Email"
                      type="text"
                      class="form-control"
                      placeholder="E-mail"
                      :class="{ 'is-invalid': submitted && $v.form.guardian1Email.$error }"
                    />
                    <div v-if="submitted && $v.form.guardian1Email.$error" class="invalid-feedback">
                      <span v-if="!$v.form.guardian1Email.email">Wpisz poprawny adres e-mail.</span>
                      <span v-if="!$v.form.guardian1Email.isNotSameAsGuardian2Email">Adresy e-mail opiekunów muszą być różne.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="guardian1Pesel">PESEL:</label>
                    <input
                      id="guardian1Pesel"
                      v-model="form.guardian1Pesel"
                      v-mask="'###########'"
                      type="text"
                      class="form-control"
                      placeholder="PESEL"
                      :class="{ 'is-invalid': submitted && $v.form.guardian1Pesel.$error }"
                    />
                    <div v-if="submitted && $v.form.guardian1Pesel.$error" class="invalid-feedback">
                      <span v-if="!$v.form.guardian1Pesel.isPesel">PESEL musi mieć 11 cyfr.</span>
                    </div>
                  </div>
                </div>
              </div>

             <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="guardian1Phone">Telefon:</label>
                    <input
                      id="guardian1Phone"
                      v-model="form.guardian1Phone"
                      type="text"
                      class="form-control"
                      placeholder="Telefon"
                      :class="{ 'is-invalid': submitted && $v.form.guardian1Phone.$error }"
                    />
                    <div v-if="submitted && $v.form.guardian1Phone.$error" class="invalid-feedback">
                      <span v-if="!$v.form.guardian1Phone.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
              </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <b-form-checkbox switch class="mb-2" v-model="form.guardian1AccessToApp">
                    <label>
                      <i class="bx bx-info-circle" v-b-tooltip.hover
                         title="Włączenie tej opcji spowoduje, że opiekuna otrzyma dostęp do przeglądania informacji o podopiecznym w ramach aplikacji dla podopiecznych.
                         Jeżeli opiekun jest nowy, to system wyśle do niego e-mail z powiadomieniem i wygenerowanym hasłem.">
                      </i>
                      Włącz dostęp do przeglądania danych tego podopiecznego
                    </label>
                  </b-form-checkbox>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Dane opiekuna 2</h4>
            <p
              class="card-title-desc"
            >Uzupełnij dane opiekuna.</p>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="guardian2Name">Imię i nazwisko:</label>
                    <input
                      id="guardian2Name"
                      v-model="form.guardian2Name"
                      type="text"
                      class="form-control"
                      placeholder="Imię i nazwisko"
                      value=""
                      :class="{ 'is-invalid': submitted && $v.form.guardian2Name.$error }"
                    />
                    <div v-if="submitted && $v.form.guardian2Name.$error" class="invalid-feedback">
                      <span v-if="!$v.form.guardian2Name.required">Ta wartość jest wymagana.<br></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="guardian2Email">E-mail:</label>
                    <input
                      id="guardian2Email"
                      v-model="form.guardian2Email"
                      type="text"
                      class="form-control"
                      placeholder="E-mail"
                      :class="{ 'is-invalid': submitted && $v.form.guardian2Email.$error }"
                    />
                    <div v-if="submitted && $v.form.guardian2Email.$error" class="invalid-feedback">
                      <span v-if="!$v.form.guardian2Email.email">Wpisz poprawny adres e-mail.</span>
                      <span v-if="!$v.form.guardian2Email.isNotSameAsGuardian1Email">Adresy e-mail opiekunów muszą być różne.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="guardian2Pesel">PESEL:</label>
                    <input
                      id="guardian2Pesel"
                      v-model="form.guardian2Pesel"
                      v-mask="'###########'"
                      type="text"
                      class="form-control"
                      placeholder="PESEL"
                      :class="{ 'is-invalid': submitted && $v.form.guardian2Pesel.$error }"
                    />
                    <div v-if="submitted && $v.form.guardian2Pesel.$error" class="invalid-feedback">
                      <span v-if="!$v.form.guardian2Pesel.isPesel">PESEL musi mieć 11 cyfr.</span>
                    </div>
                  </div>
                </div>
              </div>

             <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="guardian2Phone">Telefon:</label>
                    <input
                      id="guardian2Phone"
                      v-model="form.guardian2Phone"
                      type="text"
                      class="form-control"
                      placeholder="Telefon"
                      :class="{ 'is-invalid': submitted && $v.form.guardian2Phone.$error }"
                    />
                    <div v-if="submitted && $v.form.guardian2Phone.$error" class="invalid-feedback">
                      <span v-if="!$v.form.guardian2Phone.required">Ta wartość jest wymagana.</span>
                    </div>
                  </div>
                </div>
              </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <b-form-checkbox switch class="mb-2" v-model="form.guardian2AccessToApp">
                    <label>
                      <i class="bx bx-info-circle" v-b-tooltip.hover
                         title="Włączenie tej opcji spowoduje, że opiekuna otrzyma dostęp do przeglądania informacji o podopiecznym w ramach aplikacji dla podopiecznych.
                         Jeżeli opiekun jest nowy, to system wyśle do niego e-mail z powiadomieniem i wygenerowanym hasłem.">
                      </i>
                      Włącz dostęp do przeglądania danych tego podopiecznego
                    </label>
                  </b-form-checkbox>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- end card -->

      </div>
      <!-- end col -->

    </div>
    <!-- end row -->



  </Layout>
</template>
