<script>
import Repository from "../../app/repository/repository-factory";

const SubaccountDashboardRepository = Repository.get("SubaccountDashboardRepository");

/**
 * Email-sent component
 */
export default {
  props: {
    walletUuid: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      active: 1,
      barChart: {
        series: this.getDefaultSeries(),
        chartOptions: {
          chart: { toolbar: { show: false } },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          dataLabels: { enabled: false },
          stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
          },
          colors: ['#34c38f', '#f46a6a'],
          xaxis: { categories: ['Wpłaty', 'Wypłaty'] },
          yaxis: { title: { text: 'Kwota w zł' } },
          grid: { borderColor: '#f1f1f1' },
          fill: { opacity: 1 },
          noData: { text: 'Brak danych...' },
          tooltip: {
            y: { formatter: (val) => val + " zł" }
          }
        }
      }
    }
  },
  created() {
    this.getOperationsOfLastMonths();
  },
  methods: {
    getDefaultSeries() {
      return [{
        name: 'Wpłaty',
        data: [0]
      }, {
        name: 'Wypłaty',
        data: [0]
      }];
    },

    updateChart(categories, series) {
      const updateOptions = {
        xaxis: { categories },
        ...(this.walletUuid !== null && {
          chart: { toolbar: { show: true } }
        })
      };

      this.$refs.barChart.updateOptions(updateOptions);
      this.$refs.barChart.updateSeries(series, true);
    },

    handleChartData(response) {
      if (!response.data?.labels) {
        this.updateChart(['Brak danych'], this.getDefaultSeries());
        return;
      }

      const categories = response.data.labels.length ? response.data.labels : ['Brak danych'];
      const series = [{
        name: 'Wpłaty',
        data: response.data.payIn || [0]
      }, {
        name: 'Wypłaty',
        data: response.data.payOut || [0]
      }];

      this.updateChart(categories, series);
    },

    handleError(error) {
      console.log(error);
      this.updateChart(['Brak danych'], this.getDefaultSeries());
    },

    getOperationsOfLastMonths() {
      this.active = 1;
      const parameter = this.walletUuid ? `?walletUuid=${this.walletUuid}` : '';

      SubaccountDashboardRepository.getOperationsOfLastMonths(parameter)
        .then(this.handleChartData)
        .catch(this.handleError);
    },

    getLastMonthOperationsBySubaccount() {
      this.active = 2;
      SubaccountDashboardRepository.getLastMonthOperationsBySubaccount()
        .then(this.handleChartData)
        .catch(this.handleError);
    }
  }
};
</script>
<template>
  <div class="card">
    <div class="card-body">
      <div v-if="walletUuid === null" class="float-right">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <a href="#" class="nav-link" v-bind:class="active === 2 ? 'active' : ''"
               v-on:click="getLastMonthOperationsBySubaccount">TOP 10 podopiecznych (ostatnie 30 dni)</a>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" v-bind:class="active === 1 ? 'active' : ''"
               v-on:click="getOperationsOfLastMonths">Ostatni rok</a>
          </li>
        </ul>
      </div>
      <h4 class="card-title mb-4">Statystyki - wpłaty i wypłaty</h4>

      <apexchart
          class="apex-charts"
          height="350"
          type="bar"
          dir="ltr"
          ref="barChart"
          :series="barChart.series"
          :options="barChart.chartOptions"
      ></apexchart>
    </div>
  </div>
</template>