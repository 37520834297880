import Swal from "sweetalert2";
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Preloader from "@/components/widgets/preloader";
import Multiselect from 'vue-multiselect'
import Vue from "vue";

import Repository from "../../../app/repository/repository-factory";

const ImportRepository = Repository.get("ImportRepository");
    const ImportOperationRepository = Repository.get("ImportOperationRepository");
    const ImportSubaccountRepository = Repository.get("ImportSubaccountRepository");

    export default {
        components: {Layout, PageHeader, Multiselect, Preloader},
        data() {
            return {
                isBusy: false,
                preloader: true,
                title: "Lista operacji",
                items: [
                    {
                        text: 'Importy',
                        to: {name: 'Importy'}
                    },
                    {
                        text: 'Lista operacji',
                        active: true,
                    },
                ],
                importDetails: null,
                bookedDisabled: false,
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                pageOptions: [10, 25, 50, 100],
                filter: "",
                filterOn: [''],
                filters: {
                    visible: 'all'
                },
                sortBy: 'data',
                sortDesc: true,
                fields: [],
                data: [],
                selectValue: null,
                selectOptions: []
            };
        },
        created() {
            this.getSubaccount();
            this.getImportDetails();
        },
        watch: {
            filters: {
                handler: function () {
                    this.currentPage = 1;
                    this.refreshGrid();
                },
                deep: true
            },
        },
        methods: {
            refreshGrid() {
                this.$refs['import-operation-grid'].refresh();
            },
            updateSelected(object, rowIndex) {
                let assignedTo = null;

                if (object.assignedTo === null) {
                    assignedTo = null;
                } else {
                    assignedTo = object.assignedTo.uuid;
                }
                const payload = {
                    importUuid: '',
                    assignedUuid: assignedTo,
                    version: object.version,
                };
                ImportOperationRepository.assignToSubaccount(payload, object.uuid).then(() => {
                    Vue.swal({
                        icon: "success",
                        toast: true,
                        position: 'top',
                        title: "SUKCES! ",
                        text: 'Dane zostały zaktualizowane!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    this.data[rowIndex].version = this.data[rowIndex].version + 1;
                    this.getImportDetails();
                }).catch(error => {
                    Vue.swal({
                        icon: "error",
                        position: 'top',
                        title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                        text: error.response.data.message,
                        showConfirmButton: true,
                    });
                    this.refreshGrid();
                })
            },
            getSubaccount() {
                if (this.$route.params.id) {
                    ImportSubaccountRepository.getAll().then((response) => {
                        this.selectOptions = response.data;
                    }).catch(error => {
                        console.log(error);
                    })
                }
            },
            getImportDetails() {
                if (this.$route.params.id) {
                    ImportRepository.getWithCountOperations(this.$route.params.id).then((response) => {
                        this.importDetails = response.data;
                        this.preloader = false;
                    }).catch(error => {
                        console.log(error);
                    })
                }
            },
            markAsBooked() {
                Vue.swal({
                    icon: "info",
                    toast: false,
                    position: 'top',
                    title: "Czy na pewno chcesz to robić? ",
                    text: 'Po zatwierdzeniu rozpocznie się proces księgowania operacji na kontach podopiecznych.',
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Tak, rozlicz!"
                }).then(result => {
                    if (result.value) {
                        ImportRepository.booked(this.$route.params.id).then(() => {
                            Vue.swal({
                                icon: "success",
                                toast: false,
                                position: 'top',
                                title: "SUKCES! ",
                                text: 'Księgowanie operacji zostało dodane do kolejki i w ciągu kilku minut konta podopiecznych zostaną zaktualizowane!',
                                showConfirmButton: false,
                                timer: 4000
                            });
                            this.bookedDisabled = true;
                            this.refreshGrid();
                        }).catch(error => {
                            Vue.swal({
                                icon: "error",
                                position: 'top',
                                title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                                text: error.response.data.message,
                                showConfirmButton: true
                            });
                        })
                    }
                });

            },
            axiosProvider(ctx) {
                const parameters = '?importUuid=' + this.$route.params.id + '&page=' + ctx.currentPage +
                    '&maxItems=' + ctx.perPage + '&search=' + this.filter + '&orderBy=' + this.sortBy +
                    '&orderDesc=' + this.sortDesc + '&filterVisible=' + this.filters.visible;
                const promise = ImportOperationRepository.getGrid(parameters);

                return promise.then((data) => {
                    const fields = data.data.info.fields;
                    this.data = data.data.data;
                    this.totalRows = data.data.info.countAllItems;

                    this.title = 'Lista operacji z dnia ' + this.importDetails.date + '  |  źródło: ' + this.importDetails.source;

                    let fieldsArray = [];
                    fieldsArray.push({'key': 'action', 'label': "Przypisz"});
                    Object.entries(fields).forEach(([key, val]) => {
                        if (val.disabled !== true) {
                            val.key = key;
                            fieldsArray.push(val);
                        }
                    });

                    this.fields = fieldsArray;
                    return data.data.data || []
                }).catch(error => {
                    console.log(error);
                    return []
                })
            },
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <preloader v-if="preloader"></preloader>

        <template v-if="!preloader">
            <div class="row">
                <div class="col-12">

                    <template
                            v-if="1 === 0 && importDetails !== null && importDetails.name !== null && importDetails.name !== ''">
                        Nazwa importu: <b>{{ importDetails.name }}</b>
                    </template>

                    <div class="card">
                        <div class="card-body">
                            <div class="row mb-2">
                                <div class="col-sm-7 col-lg-6">
                                    <div class="d-inline-flex align-items-center">
                                        <i v-on:click="refreshGrid" v-b-tooltip.hover title="Przeładuj dane"
                                           class="bx bx-sync font-size-24 mr-2 cursor-pointer"></i>
                                        <div class="d-flex align-items-center mb-2 mb-sm-0 mr-sm-2">
                                          <b-form-select v-model="perPage" :options="pageOptions" class="mr-1" style="width: 60px"></b-form-select>
                                        </div>
                                        <div class="search-box" style="min-width: 300px">
                                            <div class="position-relative">
                                                <b-form-input v-model="filter" :debounce="350" placeholder="Szukaj..."></b-form-input>
                                                <i class="bx bx-search-alt search-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-5 col-lg-6">
                                    <div class="float-sm-right">
                                        <button class="btn btn-success waves-effect waves-light mb-2 ml-2"
                                                v-b-tooltip.hover
                                                :title="'Przypisanych operacji ' + importDetails.countOperations.assigned + ' z ' + importDetails.countOperations.all"
                                                :class=" { 'btn-danger': importDetails.countOperations.assigned !== importDetails.countOperations.allToAssigned,
                                                'btn-success': importDetails.countOperations.assigned === importDetails.countOperations.allToAssigned }">
                                            {{ importDetails.countOperations.assigned}} z {{ importDetails.countOperations.allToAssigned }}
                                        </button>
                                            <button type="button" v-on:click="markAsBooked"
                                                    :disabled="bookedDisabled || importDetails.booked === true || importDetails.countOperations.assigned !== importDetails.countOperations.allToAssigned"
                                                    class="btn btn-primary waves-effect waves-light mb-2 ml-2">
                                                <i class="bx bx-line-chart mr-1"></i> Rozlicz operacje
                                            </button>
                                    </div>
                                    <b-form inline class="float-sm-right">
                                        <select class="ml-2 form-control" v-model="filters.visible">
                                            <option value="all">Pokaż wszystkie - {{ importDetails.countOperations.all }}</option>
                                            <option value="unassigned">Pokaż tylko nieprzypisane - {{ importDetails.countOperations.unassigned }}</option>
                                            <option value="assigned">Pokaż tylko przypisane - {{ importDetails.countOperations.assigned }}</option>
                                            <option value="duplicated">Pokaż tylko zdublowane - {{ (importDetails.countOperations.all - importDetails.countOperations.allToAssigned) }}</option>
                                        </select>
                                    </b-form>
                                </div>
                            </div>

                            <!-- End search -->
                            <!-- Table -->
                            <div class="table-responsive mb-0">
                                <b-table id="import-operation-grid" ref="import-operation-grid" style="min-height: 700px;"
                                         :busy="isBusy"
                                         :items="axiosProvider"
                                         :fields="fields"
                                         responsive="md"
                                         :per-page="perPage"
                                         :current-page="currentPage"
                                         :sort-by.sync="sortBy"
                                         :sort-desc.sync="sortDesc"
                                         :filter="filter"
                                         :striped=true
                                         :filter-included-fields="filterOn">

                                    <template v-slot:table-busy>
                                        <div class="text-center">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong> Ładowanie danych...</strong>
                                        </div>
                                    </template>

                                    <template v-slot:table-colgroup="scope">
                                        <col
                                                v-for="field in scope.fields"
                                                :key="field.key"
                                                :style="{width: field.key === 'amount' || 'date' ? '22px' : ''}">
                                    </template>

                                    <template v-slot:cell(amount)="data">
                                      <template v-if="data.item.ignored === true">
                                         <span class="font-weight-bold strok">
                                           <del>{{ data.value.toFixed(2) }} zł</del>
                                        </span>
                                        <i v-b-tooltip.hover :title="'Operacja ignorowana przez system.'"
                                           style="color: red; margin-left: 5px;" class="fas fa-lock"></i>
                                      </template>

                                        <template v-else-if="data.item.duplicatedOperationUuid !== null">
                                           <span class="font-weight-bold">
                                            {{ data.value.toFixed(2) }} zł
                                           </span>
                                            <i v-b-tooltip.hover :title="'Operacja zdublowana z ID: ' + data.item.duplicatedOperationUuid"
                                               style="color: red;" class="fas fa-shield-alt"></i>
                                        </template>

                                      <template v-else>
                                           <span class="font-weight-bold">
                                            {{ data.value.toFixed(2) }} zł
                                           </span>
                                      </template>
                                    </template>

                                    <template v-slot:cell(booked)="data">
                                        <template
                                                v-if="data.item.assignedBy !== null && data.item.assignedBy.name === 'Automat'">
                                            <i v-b-tooltip.hover
                                               :title="'Przypisane przez: ' + data.item.assignedBy.name"
                                               class="fas fa-robot"></i>
                                        </template>

                                      <template
                                        v-if="data.item.assignedBy !== null && data.item.assignedBy.name === 'AI'">
                                        <i v-b-tooltip.hover
                                           :title="'Przypisane przez: ' + data.item.assignedBy.name"
                                           class="fas fa-brain"></i>
                                      </template>

                                        <template
                                                v-if="data.item.assignedBy !== null && data.item.assignedBy.name !== 'Automat' && data.item.assignedBy.name !== 'AI'">
                                            <i v-b-tooltip.hover
                                               :title="'Przypisane przez: ' + data.item.assignedBy.name"
                                               class="fas fa-user-check"></i>
                                        </template>

                                        <template v-if="data.item.assignedBy === null">
                                            <i v-b-tooltip.hover title="Brak przypisania" class="fas fa-user-clock"></i>
                                        </template>
                                        /
                                        <span class="badge badge-pill badge-soft-success font-size-12"
                                              :title="data.value ? 'Operacja rozliczona' : 'Operacja nierozliczona'"
                                              v-b-tooltip.hover
                                              :class=" { 'badge-soft-danger': data.value === false,
                                                'badge-soft-success': data.value === true }">
                                            {{ data.value ? 'Tak' : 'Nie' }}
                                        </span>
                                    </template>

                                    <template v-slot:cell(action)="row">
                                        <multiselect width="500"
                                                     :disabled="data[row.index].booked === true || data[row.index].duplicatedOperationUuid !== null || data[row.index].ignored === true"
                                                     v-model="data[row.index].assignedTo"
                                                     :options="selectOptions"
                                                     :optionsLimit=1000
                                                     :preserve-search="true"
                                                     track-by="uuid"
                                                     label="fullName"
                                                     :placeholder="(data[row.index].duplicatedOperationUuid !== null || data[row.index].ignored === true) ? 'Zablokowane' : 'Wybierz'"
                                                     selectLabel=""
                                                     deselectLabel="Odznacz"
                                                     selectedLabel=""
                                                     @close="updateSelected(data[row.index], row.index)">
                                            <template v-slot:noResult>
                                                Brak rezultatów.
                                            </template>

                                        </multiselect>
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <template v-if="totalRows === 0">
                                        <div class="d-flex justify-content-center mt-3">
                                            <b>Nie znaleziono elementów</b>
                                        </div>
                                    </template>
                                    <template v-if="totalRows > 0">
                                        <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                                            Znaleziono {{ totalRows }} rekordów.
                                        </div>
                                    </template>
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="totalRows"
                                                          :per-page="perPage"
                                                          first-number last-number></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Layout>
</template>
