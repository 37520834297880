import Client from '../axios-client';

const resource = '/subaccount/subaccount';

export default {
    // Dodawanie nowego podopiecznego
    addFosterChild(subaccountUuid, payload) {
        return Client.post(`${resource}/${subaccountUuid}/foster-children`, payload);
    },

    // Aktualizacja danych podopiecznego
    updateFosterChild(subaccountUuid, fosterChildUuid, payload) {
        return Client.put(`${resource}/${subaccountUuid}/foster-children/${fosterChildUuid}`, payload);
    },

    // Usuwanie podopiecznego
    deleteFosterChild(subaccountUuid, fosterChildUuid) {
        return Client.delete(`${resource}/${subaccountUuid}/foster-children/${fosterChildUuid}`);
    },
    
    // Pobieranie listy podopiecznych dla danego subkonta
    getFosterChildren(subaccountUuid) {
        return Client.get(`${resource}/${subaccountUuid}/foster-children`);
    }
}; 