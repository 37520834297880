<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ApplicationInvoiceAdd from "@/components/application/application-invoice-add";
import ApplicationRoadCardComplete from "@/components/application/application-roadcard-complete";
import ApplicationWithdrawalForm from "@/components/application/application-withdrawal-form";
import ApplicationStatuses from "@/components/application/application-statuses";
import ApplicationChangeComment from "@/components/application/application-change-comment";
import ApplicationInvoiceFraudCheck from "@/components/application/application-invoice-fraud-check";
import Preloader from "@/components/widgets/preloader";
import ApplicationChangeNumber from "@/components/application/application-change-number";
import Repository from "../../../app/repository/repository-factory";
import EventBus from "../../../event-bus";
import Vue from "vue";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");
const ApplicationSettlementRepository = Repository.get("ApplicationSettlementRepository");
const ApplicationBeneficiaryRepository = Repository.get("ApplicationBeneficiaryRepository");

export default {
  components: {
    Layout,
    PageHeader,
    Preloader,
    ApplicationInvoiceAdd,
    ApplicationRoadCardComplete,
    ApplicationWithdrawalForm,
    ApplicationStatuses,
    ApplicationChangeNumber,
    ApplicationChangeComment,
    ApplicationInvoiceFraudCheck
  },
  data() {
    return {
      title: 'Edycja wniosku',
      items: [
        {
          text: 'Wnioski',
          to: {name: 'Raport wniosków'}
        },
        {
          text: 'Edycja',
          active: true,
        },
      ],
      isNumberEdition: false,
      preloader: true,
      application: {
        number: '',
        applicationId: '',
        version: 0,
        invoices: [],
        statuses: [{
          statusName: 'in_preparation'
        }],
        roadCard: {
          amount: 0
        },
        beneficiaryId: null,
        comment: ''
      },
      settlement: {
        settlementId: null,
        version: 0,
        withdrawals: [],
        status: '',
        amountToSettled: 0
      },
      beneficiary: {
        beneficiaryId: '',
        firstName: '',
        lastName: '',
        personalAccountBalance: 0,
        publicAccountBalance: 0,
        onePercentAccountBalance: 0,
        foundationAccountBalance: 0
      },
      statuses: [
        {value: '', text: 'Wybierz status'},
        {value: 'in_preparation', text: 'W trakcie przygotowania'},
        {value: 'for_approval', text: 'Przekazany do zatwierdzenia'},
        {value: 'approved', text: 'Zatwierdzony'},
        {value: 'correction', text: 'Korekta'},
        {value: 'incorrect', text: 'Niepoprawny'},
        {value: 'in_settlement', text: 'W rozliczeniu'},
        {value: 'awaiting_settlement', text: 'Oczekuje na rozliczenie'},
        {value: 'partially_settled', text: 'Częściowo rozliczony'},
        {value: 'settled', text: 'Rozliczony'},
      ]
    };
  },
  mounted() {
    if (!this.$route.params.id) {
      return;
    }

    EventBus.$on('applicationNumberChanged', () => {
      this.isNumberEdition = false;
      this.getApplication(this.$route.params.id)
    });
    EventBus.$on('applicationChanged', () => {
      this.getApplication(this.$route.params.id)
    });
    EventBus.$on('applicationSettlementChanged', () => {
      this.getSettlement(this.$route.params.id)
      this.getBeneficiary(this.application.beneficiaryId)
    });
  },
  created() {
    if (!this.$route.params.id) {
      return;
    }

    this.getApplication(this.$route.params.id);
    this.getSettlement(this.$route.params.id);
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.preloader = true;
          this.getApplication(newId);
          this.getSettlement(newId);
        }
      }
    },
    filter: function () {
      this.currentPage = 1;
    }
  },
  methods: {
    enableNumberEdition() {
      this.isNumberEdition = true;
    },
    removeApplication() {
      if (confirm('Czy na pewno chcesz USUNĄĆ ten wniosek? Ta operacje jest nieodwracalna.')) {
        ApplicationApplicationRepository.remove(this.application.applicationId).then(() => {
          EventBus.$emit('applicationDeleted', '');
          Vue.swal({
            icon: "success",
            toast: false,
            position: 'top',
            title: "SUKCES! ",
            text: 'Wniosek został usunięty!',
            showConfirmButton: false,
            timer: 1500,
            onClose: () => {
            }
          });
          this.isBusy = false;
          this.inProgress = false;
          this.$router.push({name: "Raport wniosków"});
        }).catch(error => {
          Vue.swal({
            icon: "error",
            position: 'top',
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true
          });
          this.isBusy = false;
          this.inProgress = false;
        })
      }
    },
    removeInvoice(invoiceId) {
      this.isBusy = true;
      ApplicationApplicationRepository.removeInvoiceFromApplication(this.$route.params.id, invoiceId, this.application.version).then(() => {
        EventBus.$emit('applicationChanged', '');
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Faktura została usunięta!',
          showConfirmButton: false,
          timer: 1500,
          onClose: () => {
          }
        });
        this.isBusy = false;
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.isBusy = false;
        this.inProgress = false;
      })
    },
    getApplication(applicationId) {
      const promise = ApplicationApplicationRepository.getItem(applicationId);

      return promise.then((data) => {
        this.application = data.data;
        this.getBeneficiary(this.application.beneficiaryId);
        this.preloader = false;

      }).catch(error => {
        this.preloader = false;
        console.log(error);
      })
    },
    getSettlement(applicationId) {
      const promise = ApplicationSettlementRepository.getItem(applicationId);

      return promise.then((data) => {
        this.settlement = data.data;
        this.preloader = false;

      }).catch(error => {
        this.preloader = false;
        console.log(error);
      })
    },
    getBeneficiary(beneficiaryId) {
      const promise = ApplicationBeneficiaryRepository.getItem(beneficiaryId);

      return promise.then((data) => {
        this.beneficiary = data.data;
        this.preloader = false;

      }).catch(error => {
        this.preloader = false;
        console.log(error);
      })
    },
    getPdf: function () {
      const promise = ApplicationApplicationRepository.getPDF(this.$route.params.id);
      return promise.then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.application.number + '_wniosek.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log(error);
      })
    },
    totalInvoices() {
      let total = 0;
      for (const invoice of this.application.invoices) {
        total += invoice.amount;
      }

      return total;
    },
    totalWithdrawals() {
      let total = 0;
      for (const withdrawal of this.settlement.withdrawals) {
        total += withdrawal.amount;
      }

      return total;
    },
    lastStatus() {
      if (this.settlement?.status) {
        return this.settlement.status;
      }

      const lastStatus = this.application?.statuses?.[this.application.statuses.length - 1];
      return lastStatus?.statusName || 'in_preparation';
    },
    canEditApplication() {
      if (this.lastStatus() === 'in_preparation' || this.lastStatus() === 'correction') {
        return true;
      }

      return false;
    },
    canWithdrawal() {
      if (this.lastStatus() === 'awaiting_settlement' || this.lastStatus() === 'partially_settled') {
        return true;
      }

      return false;
    },
    remainingToBePaid() {
      return Number((this.settlement.amountToSettled - this.totalWithdrawals()).toFixed(2));
    }
  }
}
;
</script>

<template>

  <Layout>
    <preloader v-if="preloader"></preloader>
    <template v-if="!preloader">
      <PageHeader :title="title" :items="items"/>

      <b-alert 
        variant="success" 
        v-if="application && lastStatus() === 'settled'" 
        show
      >
        Procesowanie wniosku zostało zakończone!
      </b-alert>

      <div class="row">
        <div class="col-lg-3">

          <!-- Dane wniosku -->
          <div class="card">
            <div class="card-body">
              <h2 v-if="application">
                <i class="bx bx-clipboard mr-1"></i> 
                Wniosek nr. {{ application.number }}
                <i class="bx bx-edit mr-1 small" v-on:click="enableNumberEdition()" title="Zmień numer wniosku"></i>
              </h2>
              <application-change-number
                v-if="isNumberEdition && application"
                :application-id="application.applicationId"
                :full-number="application.number">
              </application-change-number>
              <hr>

              <h4 v-if="beneficiary && beneficiary.beneficiaryId">
                <i class="bx bx-user mr-3"></i>
                <router-link 
                  :to="{ 
                    name: 'Profil podopiecznego', 
                    params: { 
                      id: beneficiary.beneficiaryId 
                    }
                  }"
                >
                  {{ beneficiary.lastName }} {{ beneficiary.firstName }}
                </router-link>
              </h4>
              <h4 v-else-if="beneficiary">
                <i class="bx bx-user mr-3"></i>
                {{ beneficiary.lastName }} {{ beneficiary.firstName }}
              </h4>
              <hr>
              <h4 v-if="application"><i class="bx bx-calendar mr-3"></i>{{ application.date }}</h4>
              <hr>
              <template v-if="application">
                Utworzony przez: <strong>{{ application.createdBy }}</strong><br>
                Dnia: <strong>{{ application.createdAt }}</strong>
              </template>
              <br><br>
              <b-button variant="danger" v-on:click="removeApplication()">Usuń wniosek</b-button>
            </div>
          </div>

          <!-- Pobierz wniosek -->
          <div class="card" v-if="!canEditApplication()">
            <div class="card-body">
              <h4 class="card-title">Pobierz wniosek</h4>
              <p class="card-title-desc">
                Pobierz wniosek do akceptacji.
              </p>
              <button type="button" v-on:click="getPdf" class="btn btn-lg btn-success waves-effect waves-light">
                <i class="bx bxs-file-pdf mr-1"></i> Pobierz wniosek PDF
              </button>
            </div>
          </div>

          <!-- Karta drogowa -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Karta drogowa</h4>
              <p class="card-title-desc">
                Wypełnij dane dotyczące karty drogowej.
              </p>
              <application-road-card-complete 
                v-if="application && application.applicationId"
                :application-id="application.applicationId"
                :application-expected-version="application.version"
                :road-card="application.roadCard"
                :disabled="!canEditApplication()">
              </application-road-card-complete>
            </div>
          </div>

          <!-- Statusy -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Statusy wniosku</h4>
              <p class="card-title-desc">
              </p>

              <application-statuses 
                v-if="application && application.applicationId"
                :application-id="application.applicationId"
                :application-expected-version="application.version"
                :application-statuses="application.statuses"
                :statuses="statuses"
                :settlement-status="settlement?.status">
              </application-statuses>
            </div>
          </div>
        </div>

        <div class="col-lg-9">

          <div class="card" v-if="settlement && beneficiary">
            <div class="card-body">
              <h4 class="card-title">Aktualne saldo</h4>

              <div class="row">
                <div class="col-lg-3">
                  Konto imienne: <strong>{{ beneficiary.personalAccountBalance }} zł</strong>
                </div>
                <div class="col-lg-3">
                  Zbiórka publiczna: <strong>{{ beneficiary.publicAccountBalance }} zł</strong>
                </div>
                <div class="col-lg-3">
                  Konto 1%: <strong>{{ beneficiary.onePercentAccountBalance }} zł</strong>
                </div>
                <div class="col-lg-3">
                  Pomoc fundacji: <strong>{{ beneficiary.foundationAccountBalance }} zł</strong>
                </div>
              </div>
            </div>
          </div>

          <div class="card" v-if="settlement">
            <div class="card-body">
              <h4 class="card-title">Wypłaty</h4>
              <p class="card-title-desc">
                Dokonaj wypłaty na poczet wniosku.
              </p>

              <application-withdrawal-form 
                v-if="settlement && canWithdrawal()"
                :settlement-id="settlement.settlementId"
                :settlement-expected-version="settlement.version"
                :remaining-to-be-paid="remainingToBePaid()">
              </application-withdrawal-form>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="thead-light">
                  <tr>
                    <th>Data wypłaty</th>
                    <th>Typ konta</th>
                    <th>Kwota</th>
                    <th>Wypłacił(a)</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="withdrawal in settlement.withdrawals" :key="withdrawal.withdrawalId">
                    <th scope="row">{{ withdrawal.date }}</th>
                    <td>{{ withdrawal.beneficiaryAccountType }}</td>
                    <td>{{ withdrawal.amount.toFixed(2) }} zł</td>
                    <td><span data-toggle="tooltip" data-placement="top"
                              :title="'Dodane: ' + withdrawal.createdAt">{{ withdrawal.createdBy }}</span></td>
                  </tr>
                  <tr>
                    <th></th>
                    <td style="text-align: right;">Suma wypłat:</td>
                    <td style="font-weight: bold;">{{ totalWithdrawals().toFixed(2) }} zł</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th></th>
                    <td style="text-align: right;">Pozostało do wypłaty:</td>
                    <td style="font-weight: bold;">{{ remainingToBePaid() }}
                      zł
                    </td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Faktury</h4>
              <p class="card-title-desc">
                Dodaj wszystkie faktury przypisane do wniosku.
              </p>

              <application-invoice-add
                v-if="canEditApplication() && application && application.applicationId"
                :application-id="application.applicationId"
                :application-expected-version="application.version">
              </application-invoice-add>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="thead-light">
                  <tr>
                    <th>Numer faktury</th>
                    <th>Data faktury</th>
                    <th>Kwota faktury</th>
                    <th>Dodał(a)</th>
                    <th>Duplikaty</th>
                    <th>Akcja</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="invoice in application.invoices" :key="invoice.invoiceId">
                    <td style="font-weight: 600;">{{ invoice.number }}</td>
                    <td>{{ invoice.date }}</td>
                    <td>{{ invoice.amount.toFixed(2) }} zł</td>
                    <td><span data-toggle="tooltip" data-placement="top"
                              :title="'Dodane: ' + invoice.createdAt">{{ invoice.createdBy }}</span></td>
                    <td>
                      <application-invoice-fraud-check
                        :invoice="invoice"
                        :current-application-id="application.applicationId"
                        :beneficiary-id="application.beneficiaryId"
                      />
                    </td>
                    <td>
                      <button type="button" :disabled="!canEditApplication()"
                              v-on:click="removeInvoice(invoice.invoiceId)"
                              class="btn btn-sm btn-danger waves-effect waves-light">
                        <i class="bx bx-trash mr-1"></i> Usuń
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td style="text-align: right;">Suma faktur:</td>
                    <td style="font-weight: bold;">{{ totalInvoices().toFixed(2) }} zł</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th></th>
                    <td style="text-align: right;">Suma faktur z kartą drogową:</td>
                    <td style="font-weight: bold;">{{ (totalInvoices() + application.roadCard.amount).toFixed(2) }} zł
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Komentarz</h4>
              <p class="card-title-desc">
                Dodaj uwagi do wniosku.
              </p>

              <application-change-comment
                v-if="application && application.applicationId"
                :application-id="application.applicationId"
                :application-comment="application.comment"/>

            </div>
          </div>


        </div>
      </div>
      <!-- end row -->
    </template>
  </Layout>
</template>
