<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ApplicationReportGrid from "./application-report-grid"

export default {
  components: {Layout, PageHeader, ApplicationReportGrid},
  props: {
    beneficiaryId: {
      type: String,
      default: '',
      required: false
    },
  },
  data() {
    return {
      title: "Wnioski",
      items: [],
      form: {
        subaccount: null,
        date: null
      },
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <application-report-grid></application-report-grid>
  </Layout>
</template>
